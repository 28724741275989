.installPrompt {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    position: fixed;
    box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.75);
    bottom: 0;
    left: 50%;
    z-index: 1;
    background-color: #ffffff;
    margin-left: -200px;
}

.installDetailsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
}

.installIcon {
    padding: .5rem;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    flex: 1;
}

.installDetails {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0.5rem;
}

.installTitle {
    font-size: 1rem;
    color: #444;
}

.installURL {
    font-size: 0.75rem;
    color: #777;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 250px;
}

.installButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    background: #1159c5;
    font-size: 0.75rem;
    margin: 0.5rem;
    box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.installButton {
    color: white;
}

.installClose {
    padding: 0.5rem;
    cursor: pointer;
}